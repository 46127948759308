import React from "react"

import SEO from "../components/seo"
import Page from "../components/page"
import Container from "../components/container"
import PageTitle from "../components/page-title"

const NotFoundPage = () => (
  <Page>
    <Container>
      <SEO title="404: Página não encontrada" />
      <PageTitle>404: Página não encontrada</PageTitle>
      <p>Você entrou em uma página inexistente.</p>
    </Container>
  </Page>
)

export default NotFoundPage
